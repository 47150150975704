import Vue from 'vue';
import Buefy from 'buefy';
import 'buefy/dist/buefy.css';
import VueApollo from 'vue-apollo';
import Auth from '@tinylab-web/vue-auth';
import { mapActions } from 'vuex';
import App from './App';
import router from './router';
import store from './store';
import apolloProvider from './plugins/apollo';
import { registerFilters } from './plugins/filters';

registerFilters();

Vue.use(Auth);
Vue.use(Buefy, { defaultIconPack: 'fas' });
Vue.use(VueApollo);
Vue.config.productionTip = false;

new Vue({
  router,
  apolloProvider,
  store,
  async created() {
    if (this.$auth.getToken()) {
      this.showSidebar();
      const user = await this.$auth.getUser();
      const techUser = [
        'bastien.bressan@luni.app',
        'thomas.bardin@luni.app',
      ].includes(user?.email);
      if (techUser) {
        this.showClearCache();
      }
    } else {
      this.hideSidebar();
      await this.$router.push('/login');
    }
  },
  methods: {
    ...mapActions('global', ['showSidebar', 'hideSidebar', 'showClearCache']),
  },
  render: (h) => h(App),
}).$mount('#app');
