<script>
import { CLEAR_CACHE } from '../gql-requests';

export default {
  name: 'ClearCacheButton',
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async clearCache() {
      try {
        this.loading = true;
        const { data } = await this.$apollo.mutate({
          mutation: CLEAR_CACHE,
        });

        if (data.clearCache) {
          this.$buefy.toast.open({
            message: 'Cache cleared successfully!',
            type: 'is-success',
          });
        } else {
          this.$buefy.toast.open({
            message: 'Failed to clear cache!',
            type: 'is-danger',
          });
        }
      } catch (error) {
        this.$buefy.toast.open({
          message: 'An error occurred!',
          type: 'is-danger',
        });
        console.error(error);
      }
      this.loading = false;
    },
  },
};
</script>

<template>
  <b-button
    size="is-small"
    type="is-warning is-light"
    :rounded="true"
    icon-left="broom"
    :loading="loading"
    @click="clearCache"
  >
    Clear server-side cache
  </b-button>
</template>
