<template>
  <b-sidebar
    position="static"
    :fullheight="true"
    type="is-light"
    open
  >
    <div class="sidebar-wrapper">
      <div class="block title">
        IZICashFlow
      </div>
      <b-menu class="is-custom-mobile">
        <b-menu-list label="Menu">
          <b-menu-item
            label="By Apps"
            tag="router-link"
            to="/"
            :active="!!$route.path.match(/^\/$/)"
          />
          <b-menu-item
            label="Month To Date"
            tag="router-link"
            to="/month-to-date"
            :active="!!$route.path.match(/^\/month-to-date/)"
          />
          <b-menu-item
            label="Weekly Perf"
            tag="router-link"
            to="/weekly-perf"
            :active="!!$route.path.match(/^\/weekly-perf/)"
          />
          <b-menu-item
            label="Studio Perf"
            tag="router-link"
            to="/studio-perf"
            :active="!!$route.path.match(/^\/studio-perf/)"
          />
          <b-menu-item
            label="Revenue"
            tag="router-link"
            to="/revenue"
            :active="!!$route.path.match(/^\/revenue$/)"
          />
          <b-menu-item
            label="Spent"
            tag="router-link"
            to="/spent"
            :active="!!$route.path.match(/^\/spent$/)"
          />
          <b-menu-item
            label="LTV - CPA"
            tag="router-link"
            to="/ltv"
            :active="!!$route.path.match(/^\/ltv/)"
          />
          <b-menu-item
            label="Margin"
            tag="router-link"
            to="/margin"
            :active="!!$route.path.match(/^\/margin/)"
          />
        </b-menu-list>
      </b-menu>
    </div>
    <footer v-if="isClearCacheVisible">
      <clear-cache-button />
    </footer>
  </b-sidebar>
</template>

<script>
import ClearCacheButton from './ClearCacheButton';

export default {
  name: 'Sidebar',
  components: { ClearCacheButton },
  props: {
    isClearCacheVisible: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
footer {
  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;
}
</style>
